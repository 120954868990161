import { useContext, useEffect } from "react";
import ViewIcon from "../../../assets/icons/ViewIcon";
import useOperations from "../../../hooks/api/useOperations";
import usePagination from "../../../hooks/usePagination";
import { formatDateTime } from "../../../utils/date";
import { path } from "../../../utils/path";
import Status from "../../Status";
import ID from "../ID";
import Table from "../Table";
import WithImage from "../WithImage";
import WithLink from "../WithLink";
import statuses from "../../../utils/statuses";
import AppContext from "../../../contexts/AppContext";

const tableHead = [
  {
    title: "ID",
    target: "*",
    render: (v) => (
      <ID
        state={v.length ? { ...v[0], items: v[1], _id: v[2] } : v}
        link={
          path.iCartOperating.onlineOrders.details.url +
          `?id=${v.length ? v[0].id : v.id}`
        }
        label="View Order"
        id={v.length ? v[0].id : v.id}
      />
    ),
  },
  {
    title: "User",
    target: "customerInformation",
    render: (info) => <WithImage text={info.name} />,
  },
  {
    title: "Phone Number",
    target: "customerInformation.phoneNumber",
    render: (phoneNumber) => (
      <WithLink link={`tel:${phoneNumber}`} text={phoneNumber} />
    ),
  },
  {
    title: "Type",
    target: "type",
    render: (v) => (
      <Status text={v} className={v === "delivery" ? "info" : "status-tint5"} />
    ),
  },
  {
    title: "Address",
    target: "deliveryTo",
    render: (delivery) => (
      <WithLink
        link={`https://google.com/maps/?q=${delivery.lat},${delivery.lng}`}
        text={delivery.address}
      />
    ),
  },
  {
    title: "Order Status",
    target: "status",
    render: (v) => <Status light text={v} className={statuses[v]} />,
  },
  {
    title: "Payment Status",
    target: "paymentCompleted",
    render: (v, status = v ? "paid" : "pending") => (
      <Status light text={status} className={statuses[status]} />
    ),
  },
  {
    title: "Date",
    target: "createdAt",
    render: (v) => formatDateTime(v).dateTime,
  },
  // {
  //   title: "Action",
  //   target: "id",
  //   render: (v) => <View />,
  // },
];

function OnlineOrders({
  title = "Online Orders",
  btn1 = {
    title: "View all",
    icon: <ViewIcon />,
    link: path.iCartOperating.onlineOrders.url,
  },
  initialLimit = 5,
  hideFooter = false,
  kiosk = true,
  status,
}) {
  const { shouldRefresh, setShouldRefresh } = useContext(AppContext);
  const { getKioskOrders, getOrders, orders, loading, totalOrders } =
    useOperations();
  const { limit, pageNumber, setPageNumber } = usePagination({ initialLimit });

  const refresh = () => {
    if (kiosk) getKioskOrders(pageNumber, limit, status);
    else getOrders(pageNumber, limit, status);
  };

  useEffect(() => {
    refresh();
  }, [pageNumber, limit, kiosk]);

  useEffect(() => {
    if (shouldRefresh) {
      refresh();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  return (
    <Table
      totalCount={totalOrders}
      onPaginate={setPageNumber}
      limit={limit}
      head={
        kiosk
          ? tableHead.map((_) => ({
              ..._,
              target:
                _.target === "*"
                  ? ["order", "items", "_id"]
                  : `order.${_.target}`,
            }))
          : tableHead
      }
      loading={loading}
      data={orders.filter((_) => _.order)}
      btn1={btn1}
      title={title}
      hideFooter={hideFooter}
    />
  );
}

export default OnlineOrders;
