// // import PlusIcon from "../assets/icons/PlusIcon";
// // import { generateUUID } from "../utils/array";
// // import Button from "./Button";

// // const Group = ({ title, Element, items, setItems, AddElement, ...props }) => {
// //   const handleChange = (id, property, value) => {
// //     const updated = items.map((i) => {
// //       if (i.id === id) i[property] = value;
// //       return i;
// //     });
// //     setItems(updated);
// //   };
// //   const handleAdd = () => setItems([...items, { id: generateUUID() }]);

// //   return (
// //     <>
// //       {!AddElement && (
// //         <>
// //           {" "}
// //           <Button
// //             // style={{ marginLeft: "auto" }}
// //             width={50}
// //             onClick={handleAdd}
// //             rSvg={<PlusIcon color="var(--main-yellow)" />}
// //             className="bg-warn"
// //           />
// //           <br />
// //         </>
// //       )}
// //       <div className="list">
// //         {items.map((i, idx) => (
// //           <Element
// //             key={i._id || i.id || idx}
// //             {...i}
// //             {...props}
// //             items={items}
// //             handleChange={handleChange}
// //             remove={() => setItems(items.filter((_) => _.id !== i.id))}
// //           />
// //         ))}
// //       </div>
// //       <br />
// //       {AddElement && <AddElement onClick={handleAdd} />}
// //     </>
// //   );
// // };

// // export default Group;

// import React, { useState } from "react";
// import PlusIcon from "../assets/icons/PlusIcon";
// import { generateUUID } from "../utils/array";
// import Button from "./Button";

// const Group = ({ title, Element, items, setItems, AddElement, ...props }) => {
//   const [draggedIndex, setDraggedIndex] = useState(null);

//   const handleDragStart = (index) => {
//     setDraggedIndex(index);
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault(); // Allow the item to be dropped
//   };

//   const handleDrop = (index) => {
//     if (draggedIndex === null) return;

//     // Rearrange items
//     const updatedItems = [...items];
//     const [draggedItem] = updatedItems.splice(draggedIndex, 1); // Remove the dragged item
//     updatedItems.splice(index, 0, draggedItem); // Insert at the new index

//     setItems(updatedItems);
//     setDraggedIndex(null);
//   };

//   const handleAdd = () => setItems([...items, { id: generateUUID() }]);

//   return (
//     <>
//       {!AddElement && (
//         <>
//           <Button
//             width={50}
//             onClick={handleAdd}
//             rSvg={<PlusIcon color="var(--main-yellow)" />}
//             className="bg-warn"
//           />
//           <br />
//         </>
//       )}
//       <div className="list">
//         {items.map((item, index) => (
//           <div
//             key={item.id || index}
//             draggable
//             onDragStart={() => handleDragStart(index)}
//             onDragOver={handleDragOver}
//             onDrop={() => handleDrop(index)}
//             className="draggable-item"
//             style={{
//               border: "1px solid #ccc",
//               padding: "10px",
//               margin: "5px 0",
//               background: "#fff",
//               cursor: "move",
//             }}
//           >
//             <Element
//               {...item}
//               {...props}
//               items={items}
//               handleChange={(id, property, value) => {
//                 const updated = items.map((i) => {
//                   if (i.id === id) i[property] = value;
//                   return i;
//                 });
//                 setItems(updated);
//               }}
//               remove={() => setItems(items.filter((i) => i.id !== item.id))}
//             />
//           </div>
//         ))}
//       </div>
//       <br />
//       {AddElement && <AddElement onClick={handleAdd} />}
//     </>
//   );
// };

// export default Group;

import React, { useState } from "react";
import PlusIcon from "../assets/icons/PlusIcon"; // Import your PlusIcon component
import { generateUUID } from "../utils/array"; // Import the generateUUID function
import Button from "./Button"; // Import the Button component

const Group = ({ title, Element, items, setItems, AddElement, ...props }) => {
  const [draggedIndex, setDraggedIndex] = useState(null);

  // Start dragging an item
  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  // Allow dropping an item
  const handleDragOver = (event) => {
    event.preventDefault(); // Necessary to allow dropping
  };

  // Handle the drop and rearrange the items
  const handleDrop = (index) => {
    if (draggedIndex === null) return;

    // Create a copy of the items list
    const updatedItems = [...items];

    // Remove the dragged item from the list
    const [draggedItem] = updatedItems.splice(draggedIndex, 1);

    // Insert the dragged item at the new index
    updatedItems.splice(index, 0, draggedItem);

    // Update the list state
    setItems(updatedItems);

    // Reset the draggedIndex
    setDraggedIndex(null);
  };

  // Add a new item to the list
  const handleAdd = () => setItems([...items, { id: generateUUID() }]);

  return (
    <>
      {!AddElement && (
        <>
          <Button
            width={50}
            onClick={handleAdd}
            rSvg={<PlusIcon color="var(--main-yellow)" />}
            className="bg-warn"
          />
          <br />
        </>
      )}
      <div className="list">
        {items.map((item, index) => (
          <div
            key={item.id || index}
            draggable
            onDragStart={() => handleDragStart(index)} // Track the start of dragging
            onDragOver={handleDragOver} // Allow the item to be dropped here
            onDrop={() => handleDrop(index)} // Handle the drop
            className="draggable-item"
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              margin: "5px 0",
              background: "#fff",
              cursor: "move",
              transition: "all 0.2s ease",
            }}
          >
            <Element
              {...item}
              {...props}
              items={items}
              handleChange={(id, property, value) => {
                const updated = items.map((i) => {
                  if (i.id === id) i[property] = value;
                  return i;
                });
                setItems(updated);
              }}
              remove={() => setItems(items.filter((i) => i.id !== item.id))}
            />
          </div>
        ))}
      </div>
      <br />
      {AddElement && <AddElement onClick={handleAdd} />}
    </>
  );
};

export default Group;
