import {
  excludeFromObject,
  generateUUID,
  selectFromObject,
} from "../../utils/array";
import { useEffect, useState } from "react";
import Group from "../FlexGroup";
import Button from "../Button";
import Input from "../Input";
import urls from "../../api/urls";
import useInventory from "../../hooks/api/useInventory";
import Image from "../Image";
import Collapsable from "../Collapsable";
import { units } from "../../utils/vars";

const Item = ({
  id,
  name,
  remove,
  storage,
  specification,
  unitOfMeasure,
  handleChange,
  deleteIngredient,
  _id,
  loading,
  material,
}) => {
  const [addManually, setAddManually] = useState(!!_id);
  const [search, setSearch] = useState("");
  const { searchMaterial, materials } = useInventory();

  const toggleAddManually = () => setAddManually(!addManually);

  useEffect(() => {
    searchMaterial(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <Collapsable title={name}>
      <div className="submit-menu-card">
        {!addManually && (
          <Input value={search} placeholder="Search" onChange={setSearch} />
        )}
        <button
          onClick={toggleAddManually}
          className="btn-plain btn-add fs-11 f-700 text-primary"
        >
          {addManually ? "Search Item" : "Add Item Mannually"}
        </button>
        <br />
        <div className="d-flex flex-wrap gap-10">
          {!addManually &&
            materials.map((_, idx) => (
              <div
                onClick={() => {
                  handleChange(id, "unitOfMeasure", _.unitOfMeasure);
                  handleChange(id, "material", _._id);
                  handleChange(id, "name", _.name);
                  toggleAddManually();
                  setSearch("");
                  console.log(addManually);
                }}
                style={{ padding: 4, paddingRight: 10 }}
                key={idx}
                className="d-flex card align-center"
              >
                <Image
                  src={_.imageUrl}
                  style={{
                    width: 40,
                    marginRight: 4,
                    height: 40,
                    borderRadius: "50%",
                  }}
                />
                <div>
                  <span className="d-block fs-13 f-700 text-brown">
                    {_.name}
                  </span>
                  <span className="d-block fs-10 f-400">
                    {_.tags.join(", ")}
                  </span>
                </div>
              </div>
            ))}
        </div>
        <br />
        {addManually && (
          <>
            <div className="inputs">
              <Input
                contentEditable={!material}
                value={name}
                placeholder="Name"
                onChange={(v) => handleChange(id, "name", v)}
              />
              <Input
                disabled={!material}
                select
                options={units.map((_) => ({ label: _.name, value: _.value }))}
                value={unitOfMeasure}
                placeholder="Unit of Measure"
                onChange={(v) => handleChange(id, "unitOfMeasure", v)}
              />
            </div>
            <br />
            {!material && (
              <div className="inputs">
                <Input
                  contentEditable={!material}
                  value={specification}
                  placeholder="Specification"
                  onChange={(v) => handleChange(id, "specification", v)}
                />
                <Input
                  contentEditable={!material}
                  value={storage}
                  placeholder="Storage Requirement"
                  onChange={(v) => handleChange(id, "storage", v)}
                />
              </div>
            )}
          </>
        )}
        <br />
        <Button
          loading={loading}
          outline
          style={{ marginLeft: "auto" }}
          onClick={() => {
            if (_id) deleteIngredient(_id);
            else remove();
          }}
          borderColor="var(--alerts-danger)"
          title="Remove"
          color="danger-text"
          fontSize="fs-12"
          width={80}
          height={30}
        />
      </div>
      <br />
    </Collapsable>
  );
};

const AddButton = (props) => (
  <Button
    {...props}
    style={{ marginLeft: "auto" }}
    title="Add Item"
    fontSize="fs-12"
    width={80}
    height={30}
  />
);

function Ingredients({
  onFinished,
  getConcept,
  id,
  addDataToConcept,
  concept,
  loading,
}) {
  const [ingredients, setIngredients] = useState(
    concept?.ingredients
      ? concept.ingredients.map((_, idx) => ({
          ...selectFromObject(
            [
              "name",
              "specification",
              "storage",
              "unitOfMeasure",
              "_id",
              "material",
            ],
            _
          ),
          id: generateUUID(),
        }))
      : []
  );

  const saveIngredients = async () => {
    const response = await addDataToConcept(
      id,
      {
        ingredients: ingredients.map((_) => ({
          ...excludeFromObject(["id", "_id"], _),
          material: _.material?._id,
          id: _?._id,
        })),
      },
      urls.business.concepts.addIngredients
    );
    if (response) onFinished(response);
  };

  const deleteIngredient = async (item) => {
    const response = await addDataToConcept(
      id,
      {
        ingredients: [item],
      },
      urls.business.concepts.addIngredients,
      true
    );
    if (response) getConcept(id);
  };

  return (
    <div>
      <span className="fs-14 d-block text-brown f-700">Ingredients</span>
      <br />
      <Group
        Element={Item}
        loading={loading}
        items={ingredients}
        AddElement={AddButton}
        setItems={setIngredients}
        deleteIngredient={deleteIngredient}
      />
      <br />
      <Button
        loading={loading}
        onClick={saveIngredients}
        title="Save & Continue"
      />
    </div>
  );
}

export default Ingredients;
